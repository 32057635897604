import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-jsa-pt/src/components/layout.js";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Privacidade" description="JSA Instalações Eléctricas, Honestidade, transparência, eficácia e eficiência aliada a todo o know-how adquirido, são os grandes pilares da JSA, habilitando-a para enfrentar o cada vez mais exigente e competitivo mercado da construção civil..." image={'https://www.jsa.pt' + ogImage} siteUrl={'https://www.jsa.pt' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p><strong parentName="p"><em parentName="strong">{`A JSA respeita a sua privacidade e compromete-se a proteger os seus dados pessoais.`}</em></strong></p>
      <p>{`Este aviso de privacidade informa sobre como cuidamos dos seus dados pessoais quando você visita o nosso site ou se comunica conosco e fala sobre os seus direitos de privacidade e como a lei o protege.`}</p>
      <h3>{`Quem nós somos`}</h3>
      <h4>{`Objetivo deste Aviso de Privacidade`}</h4>
      <p>{`Este aviso de privacidade tem como objetivo fornecer informações sobre como nós recolhemos e processamos os seus dados pessoais através da utilização do nosso site ou de outra forma quando você se comunica ou interage conosco.`}</p>
      <p>{`Este site não se destina a crianças e não recebemos intencionalmente dados relativos a crianças.`}</p>
      <h4>{`Controlador de dados`}</h4>
      <p>{`A Appuro Inc, negociando em nome da JSA, é a controladora dos seus dados pessoais. Somos responsáveis pela sua segurança e por garantir que o utilizemos apenas para os fins descritos neste aviso de privacidade.`}</p>
      <p>{`Se você tiver alguma dúvida sobre este aviso de privacidade, incluindo qualquer solicitação para exercer os seus direitos legais, entre em contacto conosco usando as nossas informações abaixo.`}</p>
      <h4>{`Detalhes do contacto`}</h4>
      <p>{`Somos a Appuro Inc, negociando em nome da JSA com nosso escritório registado em Delaware.`}</p>
      <p>{`Se necessitar de nos contactar por qualquer motivo em relação aos seus dados pessoais, envie um e-mail para support@`}{`﻿`}{`appuro.com`}</p>
      <p>{`Você tem o direito de fazer uma reclamação a qualquer momento à autoridade supervisora dos Estados Unidos para questões de proteção de dados. Gostaríamos, entretanto, de ter a oportunidade de tratar das suas preocupações sobre proteção de dados antes de entrar em contacto com a a autoridade supervisora, portanto, entre em contacto conosco em primeiro lugar.`}</p>
      <h4>{`Mudanças no Aviso de Privacidade`}</h4>
      <p>{`Esta versão foi atualizada pela última vez em 19 de Junho de 2020. É importante que os dados pessoais que mantemos sobre você sejam precisos e atualizados. Mantenha-nos informados se seus dados pessoais mudarem durante o relacionamento connosco.`}</p>
      <h4>{`Links de terceiros`}</h4>
      <p>{`Este site pode incluir links para sites, plug-ins e aplicações de terceiros. Clicar nesses links ou ativar essas ligações pode permitir que terceiros recebam ou partilhem dados sobre você. Não controlamos esses sites de terceiros e não somos responsáveis pelas suas declarações de privacidade. Ao sair do nosso site, recomendamos que você leia o aviso de privacidade de cada site que visitar.`}</p>
      <h3>{`Dados que recebemos sobre você`}</h3>
      <p>{`Dados pessoais, ou informações pessoais, significam qualquer informação sobre um indivíduo a partir da qual essa pessoa possa ser identificada. Não inclui dados em que a identidade foi removida (dados anônimos). Podemos recolher, usar, armazenar e transferir diferentes tipos de dados pessoais sobre você que agrupamos a seguir:`}</p>
      <ul>
        <li parentName="ul">{`Os dados do assinante incluem nome, endereço de e-mail e país`}</li>
        <li parentName="ul">{`Os dados do utilizador incluem informações sobre como você utiliza o nosso site`}</li>
        <li parentName="ul">{`Os dados técnicos incluem endereço de protocolo de internet, os seus dados de login, tipo e versão do navegador, fuso horário, configuração e localização, tipos e versões de plug-ins de navegador, sistema operacional e plataforma e outros tecnologia nos dispositivos que você usa para aceder este site`}</li>
        <li parentName="ul">{`Os dados de marketing incluem as suas preferências em receber marketing de nós`}</li>
      </ul>
      <p>{`Também recolhemos, usamos e partilhamos dados agregados, como dados estatísticos ou demográficos, para qualquer propósito.`}</p>
      <p>{`Os dados agregados podem ser derivados dos seus dados pessoais, mas não são considerados dados pessoais por lei, pois esses dados não revelam direta ou indiretamente a sua identidade. Por exemplo, podemos agregar seus dados de utilizador para calcular a porcentagem de utilizadores que acessam a um recurso específico do site. No entanto, se combinarmos ou conectarmos os dados agregados aos seus dados pessoais para que possam identificá-lo direta ou indiretamente, trataremos os dados combinados como dados pessoais que serão usados de acordo com este aviso.`}</p>
      <h3>{`Como os seus dados pessoais são recolhidos?`}</h3>
      <p>{`Usamos métodos diferentes para recebermos dados de e sobre você, incluindo por meio de:`}</p>
      <h4>{`Interações diretas`}</h4>
      <p>{`Recolhemosa a maioria dos seus dados quando você opta por fornecê-los no nosso site, por e-mail ou de outra forma.`}</p>
      <p>{`Tecnologias ou interações automatizadas`}</p>
      <ul>
        <li parentName="ul">{`Para fornecer os serviços de acordo com os termos de utilização do nosso site ou o nosso ou outro contrato
que temos consigo; ou`}</li>
        <li parentName="ul">{`Quando for necessário para os nossos interesses legítimos ou de terceiros onde precisamos cumprir uma obrigação legal ou regulamentar;`}</li>
        <li parentName="ul">{`Onde temos o seu livre consentimento, específico e informado para usar os seus dados para um propósito particular`}</li>
      </ul>
      <h4>{`Nossos Cookies`}</h4>
      <p>{`Você pode optar por desactivar ou recusar determinados cookies que usamos quando não são essenciais para o funcionamento do nosso site. Se você decidir fazer isso, algumas partes do nosso site podem se tornar inacessíveis ou não funcionar corretamente.`}</p>
      <h3>{`Como usamos seus dados pessoais`}</h3>
      <p>{`Só usaremos seus dados pessoais quando a lei permitir. Usaremos os seus dados pessoais nas seguintes circunstâncias:`}</p>
      <ul>
        <li parentName="ul">{`Nossos fornecedores de serviços atuam como processadores que podem ter servidores baseados nos Estados Unidos ou fora da Europa`}</li>
        <li parentName="ul">{`Área econômica que fornece suporte de relacionamento com o cliente, backup, armazenamento de dados, gestão de e-mail e serviços de administração de sistema Prestadores de serviços`}</li>
        <li parentName="ul">{`Alternativamente, podemos procurar adquirir outras empresas ou nos fundir com elas`}</li>
        <li parentName="ul">{`Se uma mudança acontecer no nosso negócio, os novos proprietários podem usar seus dados pessoais da mesma maneira conforme estabelecido neste aviso de privacidade`}</li>
        <li parentName="ul">{`Agentes de cobrança de dívidas onde precisamos recuperar dívidas para nós`}</li>
      </ul>
      <p>{`Entre em contacto conosco em support@`}{`﻿`}{`appuro.com se precisar de detalhes sobre o fundamento jurídico específico em que contamos para processar seus dados pessoais.`}</p>
      <p>{`Optar por sair`}</p>
      <p>{`Podemos usar os seus dados de assinante, técnicos e de utilizador para formar uma visão sobre o que achamos que você pode querer, ou o que pode ser do seu interesse. Você receberá nossas comunicações de marketing se tiver optado por fazê-lo ou se tiver criado uma conta conosco e não tiver optado por não receber esse marketing.`}</p>
      <p>{`Obteremos o seu consentimento expresso antes de partilharmos os seus dados pessoais com qualquer outra empresa para fins de marketing. Você pode pedir que nós ou terceiros paremos de enviar mensagens de marketing a qualquer momento, entrando na sua conta e marcando ou desmarcando as caixas relevantes para ajustar suas preferências de marketing ou seguindo os links de cancelamento de inscrição em qualquer mensagem de marketing enviada a você.`}</p>
      <p>{`Você também pode cancelar o recebimento de qualquer mensagem que não seja de marketing, simplesmente clicando no link de cancelamento de inscrição.`}</p>
      <h3>{`Dados do Facebook`}</h3>
      <p>{`Este site não faz parte do site do Facebook ou Facebook Inc. Além disso, este site não é endossado pelo Facebook de nenhuma forma ou forma. Facebook é uma marca comercial da Facebook, Inc.`}</p>
      <h3>{`Divulgação de seus dados pessoais`}</h3>
      <p>{`Precisaremos partilhar os seus dados pessoais com as entidades definidas abaixo para os fins acima definidos.`}</p>
      <ul>
        <li parentName="ul">{`Solicite o acesso aos seus dados pessoais como solicitação de acesso do titular dos dados`}</li>
        <li parentName="ul">{`Solicite a correção dos seus dados pessoais que mantemos sobre sí.`}</li>
        <li parentName="ul">{`Solicite a remoção dos seus dados pessoais.`}</li>
        <li parentName="ul">{`Solicite a restrição de processamento de seus dados pessoais`}</li>
        <li parentName="ul">{`Solicite a transferência de seus dados pessoais para você ou para terceiros`}</li>
      </ul>
      <p>{`Exigimos que todos os terceiros respeitem a segurança dos seus dados pessoais e os tratem de acordo com a lei.
Não permitimos que nossos fornecedores de serviços terceirizados usem seus dados pessoais para seus próprios fins e apenas permitimos que processem seus dados pessoais para fins específicos e de acordo com nossas instruções.`}</p>
      <h3>{`Transferências Internacionais`}</h3>
      <p>{`Vários dos nossos prestadores de serviços que processam os seus dados pessoais em nosso nome têm ou têm servidores localizados fora do Espaço Económico Europeu, pelo que o processamento dos seus dados pessoais envolve uma transferência de dados para fora desta área.`}</p>
      <p>{`Sempre que transferimos seus dados pessoais para fora do Espaço Econômico Europeu, garantimos um grau semelhante de proteção a eles garantindo que:`}</p>
      <p>{`Entre em contacto conosco em support@`}{`﻿`}{`appuro.com se desejar mais informações sobre os mecanismos específicos usados por nós ao transferir seus dados pessoais para fora do EEE.`}</p>
      <h3>{`Segurança de dados`}</h3>
      <p>{`Colocamos em prática medidas de segurança adequadas para evitar que os seus dados pessoais sejam acidentalmente perdidos, usados ou acessados de forma não autorizada, alterados ou divulgados. Além disso, limitamos o acesso aos seus dados pessoais aos funcionários, agentes, contratados e outros que tenham uma necessidade comercial. Eles só irão processar os seus dados pessoais de acordo com as nossas instruções e estão sujeitos ao dever de confidencialidade.`}</p>
      <p>{`Implementamos procedimentos para lidar com qualquer suspeita de violação de dados pessoais e o notificaremos de qualquer lei aplicável sobre uma violação quando for legalmente obrigado a fazê-lo.`}</p>
      <h3>{`Retenção de dados`}</h3>
      <p>{`Iremos reter seus dados pessoais apenas pelo tempo necessário para cumprir os objetivos para os quais os coletamos, inclusive para fins de atender a quaisquer requisitos legais, contabilísticos ou de relatórios.`}</p>
      <p>{`Para determinar o período de retenção apropriado para dados pessoais, consideramos a quantidade, natureza e sensibilidade dos dados pessoais, o risco potencial de danos do uso não autorizado ou divulgação de seus dados pessoais, as finalidades para as quais processamos os seus dados pessoais e se podemos atingir esses objetivos por outros meios e pelos requisitos legais aplicáveis.`}</p>
      <p>{`Em algumas circunstâncias, você pode pedir para apagar seus dados. Em algumas circunstâncias, podemos tornar anônimos seus dados pessoais de forma que não possam mais ser associados a você para fins de pesquisa ou estatística, caso em que podemos usar essas informações indefinidamente sem aviso prévio.`}</p>
      <h3>{`Seus direitos legais`}</h3>
      <h4>{`Você tem o direito em certas circunstâncias de:`}</h4>
      <ul>
        <li parentName="ul">{`Você não terá que pagar uma taxa para aceder aos seus dados pessoais ou para exercer qualquer um dos outros direitos`}</li>
        <li parentName="ul">{`Podemos precisar de solicitar informações específicas de sí para nos ajudar a confirmar a sua identidade e garantir os seus direitos para aceder aos seus dados pessoais ou para exercer qualquer um de seus outros direitos`}</li>
        <li parentName="ul">{`Esta é uma medida de segurança para garantir que os dados pessoais não sejam divulgados a qualquer pessoa que não tenha direito a eles`}</li>
        <li parentName="ul">{`Também podemos entrar em contacto com você para pedir informações em relação à sua solicitação para acelerar a nossa resposta`}</li>
        <li parentName="ul">{`Tentamos responder a todas as solicitações legítimas dentro de um mês`}</li>
        <li parentName="ul">{`Ocasionalmente, pode levar mais de um mês se a sua solicitação for particularmente complexa`}</li>
        <li parentName="ul">{`Neste caso, iremos notificá-lo e mantê-lo atualizado`}</li>
      </ul>
      <p>{`Para obter mais informações sobre esses direitos e quando se aplicam, https​`}{`﻿`}{`://www.cnpd`}{`.`}{`com`}</p>
      <p><strong parentName="p"><em parentName="strong">{`Estes termos de utilização, juntamente com os documentos nele mencionados, estabelecem os termos em que você pode fazer uso do nosso site www.jsa`}{`.`}{`pt`}</em></strong></p>
    </MdxLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      